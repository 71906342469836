import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StreamModal } from '../StreamModal';
import { H1, P, Div, Button, H5, Span, Container, Row, Col } from '../Elements';
import { ButtonCTA } from '../buttons/Button';
import { MarkdownBlock } from '../MarkdownBlock';
import { useLoggedIn } from '../../../common/hooks/useLoggedIn';
import {
  ContentfulAssetFile,
  ContentfulAssetFileURL,
} from '../ContentfulAssetFile/ContentfulAssetFile';
import { Color } from '../Elements/types';
import PromotionalBanner, { PromotionalBannerInterface } from '../PromotionalBanner';
import getPadding from '../BladePadding';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeHero } from '../../../contentful/content-types/blades/blade-hero-animation';
import { BackgroundColor } from '../../../contentful/content-types/blades/common/interfaces/background-color.interface';
import { ContentfulPage } from '../../../contentful/content-types/page/page.interface';
import { Lazy } from '../Lazy';
import { LocaleLink } from '../links/LocaleLink';
import Text from '../Text/Text';
import { Stream } from '../Stream';
import { MarketoFormModal } from '../Marketo/marketo-form-modal'; // temporarilly commented out
import { ImageWidths } from '../../../contentful/content-types/blades/blade-hero-animation/blade-hero-animation.interface';

export function isContentfulBladeHero(blade: RelatedBlade): blade is ContentfulBladeHero {
  return blade.contentTypeId === 'bladeHeroAnimation';
}

interface HeroBladeProps {
  blade: ContentfulBladeHero;
  promotionalBanner?: PromotionalBannerInterface;
  page?: ContentfulPage;
}

const getClassnameFromBackgroundColor: { [key in BackgroundColor]: Color } = {
  white: 'white',
  'light-blue': 'blue5',
};

const getWidths = (width?: ImageWidths): [number, number, number] => {
  switch (width) {
    case 'Regular':
      return [5, 1, 5];
    case 'Medium':
      return [5, 1, 6];
    case 'Large':
      return [5, 0, 7];
    case 'Extra Wide':
      return [4, 0, 8];
    default:
      return [5, 1, 5];
  }
};

export const HeroBlade: React.FC<HeroBladeProps> = (props) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modal1IsOpen, setModal1IsOpen] = React.useState(false);
  const [modal2IsOpen, setModal2IsOpen] = React.useState(false);
  const isLoggedIn = useLoggedIn();
  const { blade, page } = props;
  // Surprisingly, the logged in version of button text 2 is not actually a button
  // It's just some text below the button
  const isOneButton = !blade.buttonText2 || (isLoggedIn && blade.buttonText2LoggedIn);
  const formModals = page?.formModal || [];
  const formModal1 = formModals.find((modal) => modal.modalId === blade.button1ExtraClass);
  const formModal2 = formModals.find((modal) => modal.modalId === blade.button2ExtraClass);
  const [popupStreamId, setPopupStreamId] = React.useState('');
  const { sectionPadding } = blade;
  const hasLeftButton =
    blade.popupStreamId ||
    blade.buttonText1 ||
    blade.buttonText1LoggedIn ||
    blade.buttonText2 ||
    blade.buttonText2LoggedIn;

  const marginBottomForMardownBlock =
    blade.leftLink && blade.leftLinkOptions === 'show' ? 3 : blade.popupStreamId ? 4 : 7;

  const [left, offset, right] = getWidths(blade.imageSize);
  return (
    <Div
      backgroundColor={getClassnameFromBackgroundColor[props.blade.backgroundColor] || 'white'}
      {...getPadding(sectionPadding, blade.contentTypeId)}
    >
      {blade.streamVideoId && (
        <StreamModal
          isOpen={modalIsOpen}
          streamId={blade.streamVideoId}
          closeModal={() => setModalIsOpen(false)}
        />
      )}
      {props.blade.popupStreamId && (
        <StreamModal
          isOpen={modalIsOpen}
          streamId={props.blade.popupStreamId}
          closeModal={() => setModalIsOpen(false)}
        />
      )}
      <Container>
        {props.page?.promotionalBanner && (
          <Row className={`items-center pb4 `}>
            <Col xs={12} sm={12} lg={7} md={12} className="ph2-ns">
              <PromotionalBanner {...props.page.promotionalBanner} />
            </Col>
          </Row>
        )}

        {modal1IsOpen &&
          formModal1 &&
          formModal1.marketoForm &&
          formModal1.marketoForm.marketoFormId && (
            <MarketoFormModal
              onSuccessBehavior="show-message"
              open={modal1IsOpen}
              setOpen={setModal1IsOpen}
              modal={formModal1}
            />
          )}
        {modal2IsOpen &&
          formModal2 &&
          formModal2.marketoForm &&
          formModal2.marketoForm.marketoFormId && (
            <MarketoFormModal
              onSuccessBehavior="show-message"
              open={modal2IsOpen}
              setOpen={setModal2IsOpen}
              modal={formModal2}
            />
          )}
        <Row alignItems={blade.imagePosition === true ? 'start' : 'center'}>
          <Col xs={12} lg={left}>
            <Div marginBottom={2}>
              <H1 marginBottom={[4, 3]}>{blade.title}</H1>
              {blade.streamVideoId && blade.includeVideo && (
                <Div paddingBottom={3} display={['block', 'block', 'block', 'none']}>
                  <ContentfulAssetFileURL
                    assetFile={blade.streamPosterAssetFile}
                    fallBackUrl={blade.streamPoster?.file?.publicURL}
                  >
                    {({ url }) =>
                      (blade.streamVideoId && (
                        <Div>
                          <Lazy>
                            <Stream
                              src={blade.streamVideoId}
                              preload="auto"
                              loop={blade.streamOptions?.includes('loop') || false}
                              muted={blade.streamOptions?.includes('muted') || false}
                              controls={blade.streamOptions?.includes('controls') || false}
                              autoplay={blade.streamOptions?.includes('autoplay') || false}
                              poster={url || blade.streamPoster?.file?.publicURL || undefined}
                              posterTime={blade.streamThumbnails ? blade.streamThumbnails : '0s'}
                            />
                          </Lazy>
                        </Div>
                      )) ||
                      null
                    }
                  </ContentfulAssetFileURL>
                </Div>
              )}
              {blade.subtitle && (
                <H5 fontSize={3} marginBottom={0}>
                  {blade.subtitle}
                </H5>
              )}
            </Div>

            <MarkdownBlock
              paragraphStyle={1}
              source={blade.copy}
              marginBottom={[6, hasLeftButton ? marginBottomForMardownBlock : 0]}
            />
            {props.blade.popupStreamId && (
              <Button
                backgroundColor="transparent"
                onClick={() => {
                  !!props.blade.popupStreamId && setPopupStreamId(props.blade.popupStreamId);
                  setModalIsOpen(true);
                }}
                textAlign="left"
                className="pointer"
                display="flex"
                alignItems="center"
                marginTop={4}
                marginBottom={6}
              >
                <Span className="pointer" display="flex" marginRight={1}>
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="13" cy="13" r="13" fill="#0055dc" />
                    <path d="M18.5715 13L9.28577 19.5V6.5L18.5715 13Z" fill="white" />
                  </svg>
                </Span>

                <Text color="blue1" tag="Span" hovered={{ color: 'bluehover' }} variant="learnmore">
                  {props.blade.popupText}
                </Text>
              </Button>
            )}
            <div className="flex flex-row flex-wrap">
              {(blade.buttonText1 || (isLoggedIn && blade.buttonText1LoggedIn)) && (
                <ButtonCTA
                  href={
                    isLoggedIn && blade.buttonUrl1LoggedIn
                      ? blade.buttonUrl1LoggedIn
                      : blade.buttonUrl1
                  }
                  openInNewTab={blade.button1OpenInNewTab}
                  buttonColor={blade.buttonColor ? blade.buttonColor : 'orange'}
                  className={`mr4-l ${blade.buttonText2 ? 'mb2  mb2-l' : 'mb0'}`}
                  bladeContentTypeId={blade.contentTypeId}
                  onClick={(e) => {
                    if (blade.buttonUrl1 === '#') {
                      e.preventDefault();
                      setModal1IsOpen(true);
                    }
                  }}
                >
                  {isLoggedIn && blade.buttonText1LoggedIn
                    ? blade.buttonText1LoggedIn
                    : blade.buttonText1}
                </ButtonCTA>
              )}
              {blade.buttonText2 && !(isLoggedIn && blade.buttonText2LoggedIn) && (
                <ButtonCTA
                  href={blade.buttonUrl2}
                  openInNewTab={blade.button2OpenInNewTab}
                  buttonColor={blade.buttonColor2 ? blade.buttonColor2 : 'orange'}
                  className="mr4-l"
                  bladeContentTypeId={blade.contentTypeId}
                  onClick={(e) => {
                    if (blade.buttonUrl2 === '#') {
                      e.preventDefault();
                      setModal2IsOpen(true);
                    }
                  }}
                >
                  {blade.buttonText2}
                </ButtonCTA>
              )}
            </div>
            {isLoggedIn && blade.buttonText2LoggedIn && blade.buttonText2 && (
              <P marginTop={0} className="body-2">
                {blade.buttonText2LoggedIn}{' '}
                <a
                  href={blade.buttonUrl2 || '#'}
                  target={blade.button2OpenInNewTab ? '_blank' : '_self'}
                  onClick={(e) => {
                    if (blade.buttonUrl2 === '#') {
                      e.preventDefault();
                      setModal2IsOpen(true);
                    }
                  }}
                >
                  {blade.buttonText2}
                </a>
              </P>
            )}
            {!isLoggedIn &&
              blade.leftLink &&
              blade.leftLinkUrl &&
              blade.leftLinkOptions === 'show' && (
                <LocaleLink to={blade.leftLinkUrl}>
                  <MarkdownBlock
                    paragraphStyle={2}
                    renderers={{
                      paragraph: ({ children }) => <P fontWeight={7}>{children}</P>,
                    }}
                  >
                    {blade.leftLink}
                  </MarkdownBlock>
                </LocaleLink>
              )}
          </Col>
          {((blade.image && blade.image.file) || blade.imageAssetFile || blade.streamVideoId) && (
            <Col lg={{ span: right, offset }}>
              <Div display={['none', 'none', 'none', 'block']}>
                {blade.streamVideoId && blade.includeVideo ? (
                  <ContentfulAssetFileURL
                    assetFile={blade.streamPosterAssetFile}
                    fallBackUrl={blade.streamPoster?.file?.publicURL}
                  >
                    {({ url }) =>
                      (blade.streamVideoId && (
                        <Div>
                          <Lazy>
                            <Stream
                              src={blade.streamVideoId}
                              preload="auto"
                              loop={blade.streamOptions?.includes('loop') || false}
                              muted={blade.streamOptions?.includes('muted') || false}
                              controls={blade.streamOptions?.includes('controls') || false}
                              autoplay={blade.streamOptions?.includes('autoplay') || false}
                              poster={url || blade.streamPoster?.file?.publicURL || undefined}
                              posterTime={blade.streamThumbnails ? blade.streamThumbnails : '0s'}
                            />
                          </Lazy>
                        </Div>
                      )) ||
                      null
                    }
                  </ContentfulAssetFileURL>
                ) : (
                  <>
                    <ContentfulAssetFileURL
                      assetFile={blade.imageAssetFile}
                      fallBackUrl={blade.image?.file?.publicURL}
                    >
                      {({ url }) =>
                        url && (
                          <Helmet>
                            <link rel="preload" as="image" href={url} />
                          </Helmet>
                        )
                      }
                    </ContentfulAssetFileURL>
                    <ContentfulAssetFile
                      assetFile={blade.imageAssetFile}
                      fallBackUrl={blade.image?.file?.publicURL}
                      className="mw-100"
                    />
                  </>
                )}
              </Div>
            </Col>
          )}
        </Row>
      </Container>
    </Div>
  );
};
