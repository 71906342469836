import React, { FC } from 'react';
import { Grid, GridRow, GridCol } from './common/Grid';
import { ResourceLinkInterface, ResourcesGrid } from './common/ResourcesGrid';
import { ContentfulAssetFile } from '../common/ContentfulAssetFile/ContentfulAssetFile';
import { HeroBlade } from '../common/refresh-blades/HeroBlade';
import { BlogDataInterface } from './interfaces/welcome-center.interface';
import { ContentfulBladeHero } from '../../contentful/content-types/blades/blade-hero-animation/blade-hero-animation.interface';

interface OverviewTabProps {
  heroSection: ContentfulBladeHero;
  fromBlog: BlogDataInterface;
  resourceGrid: {
    title: string;
    resources: ResourceLinkInterface[];
  };
}
export const OverviewTab: FC<OverviewTabProps> = ({ heroSection, resourceGrid, fromBlog }) => {
  return (
    <>
      <HeroBlade
        blade={heroSection}
        page={{
          contentTypeId: 'page',
          relativePath: '',
          contentfulId: '',
          pageName: '',
          metaTitle: '',
          metaDescription: '',
        }}
      />

      <ResourcesGrid resourceGrid={resourceGrid}></ResourcesGrid>

      <section>
        <div className="flex flex-column items-center mw9 center ph4 pv4">
          <h2 className="lh-title f6 f7-l fw7 brandGray5 w-100 mb6-l mb5 mt4-l mt0">
            {fromBlog.title}
          </h2>

          <Grid className="mb2-l">
            <GridRow className="justify-between">
              <GridCol width={2.5} className="bt b--gray5">
                <h3 className="lh-title f5 fw7 mb0">{fromBlog.firstBlurbTitle}</h3>
                <p className="lh-copy h-100">{fromBlog.firstBlurbBody}</p>
                <a href={fromBlog.firstBlurbUrl} className="fw7 gt-after">
                  {fromBlog.firstBlurbLinkText}
                </a>
              </GridCol>
              <GridCol width={2.5} className="bt b--gray5">
                <h3 className="lh-title f5 fw7 mb0">{fromBlog.secondBlurbTitle}</h3>
                <p className="lh-copy h-100">{fromBlog.secondBlurbBody}</p>
                <a href={fromBlog.secondBlurbUrl} className="fw7 gt-after">
                  {fromBlog.secondBlurbLinkText}
                </a>
              </GridCol>
              <GridCol width={2.5} className="bt b--gray5">
                <h3 className="lh-title f5 fw7 mb0">{fromBlog.thirdBlurbTitle}</h3>
                <p className="lh-copy h-100">{fromBlog.thirdBlurbBody}</p>
                <a href={fromBlog.thirdBlurbUrl} className="fw7 gt-after">
                  {fromBlog.thirdBlurbLinkText}
                </a>
              </GridCol>
            </GridRow>
          </Grid>
        </div>
      </section>
    </>
  );
};
